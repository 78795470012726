import {
  FaCheck as SuccessIcon,
  FaStream as QueuedIcon,
  FaExclamationTriangle as FailedIcon,
  FaExclamationCircle as FailedPermanantlyIcon,
} from 'react-icons/fa';
import React from 'react';
import { MessageStatus } from '../api/model';
import { IconType } from 'react-icons';

const MessageStatusCell: React.FC<{ messageStatus: MessageStatus }> = ({
  messageStatus,
}) => {
  let icon: React.ReactNode;
  switch (messageStatus) {
    case MessageStatus.SUCCESS:
      icon = <SuccessIcon />;
      break;
    case MessageStatus.FAILED:
      icon = <FailedIcon />;
      break;
    case MessageStatus.QUEUED:
      icon = <QueuedIcon />;
      break;
    case MessageStatus.FAILED_PERMANENTLY:
      icon = <FailedPermanantlyIcon className="text-red-600" />;
      break;
  }
  return (
    <div className="flex gap-2">
      {icon}
      {' ' + messageStatus}
    </div>
  );
};

export default MessageStatusCell;
