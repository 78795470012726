import _ from 'lodash';
import { Declaration } from '../api/model';

function trimRef(ref: string): string {
  return _.replace(ref, '#/components/schemas/', '');
}

function resolveRef<T extends object>(ref: string, refs: T) {
  return _.get(refs, trimRef(ref));
}

export function resolve<T extends object, T2 extends object>(
  obj: T,
  refs: T2
): Declaration | undefined {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  for (const key of _.keys(obj)) {
    if (key == '$ref') {
      return resolve(resolveRef(_.get(obj, key) as string, refs), refs);
    } else {
      _.set(obj, key, resolve(_.get(obj, key), refs));
    }
  }
  return obj as Declaration;
}
