import { Dialog } from '@headlessui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCreateTenant } from '../api/tenant-api';

const CreateTenantModal: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const { mutate: createTenant } = useCreateTenant();
  const { register, handleSubmit } = useForm<{
    tenant: number;
    location: string;
  }>();

  const onSubmit = React.useCallback(
    handleSubmit((values) => {
      createTenant(values);
      setOpen(false);
    }),
    [createTenant]
  );

  return (
    <>
      <div className="cursor-pointer" onClick={() => setOpen(true)}>
        {children}
      </div>
      <Dialog open={open} onClose={() => setOpen(false)} className="dialog">
        <div className="backdrop">
          <Dialog.Panel className="panel wrap-content flex flex-col">
            <form onSubmit={onSubmit}>
              <div className="form-control">
                <label htmlFor="location">Location:</label>
                <input
                  id="location"
                  type="text"
                  {...register('location', { required: true, minLength: 3 })}
                />
              </div>
              <div className="form-control">
                <label htmlFor="tenant">Tenant: </label>
                <input
                  type="number"
                  id="tenant"
                  {...register('tenant', { required: true })}
                />
              </div>

              <button className="btn primary w-full mt-3" type="submit">
                Create Tenant
              </button>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};
export default CreateTenantModal;
