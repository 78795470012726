import React from 'react';
import { useMappingStore } from '../store';
import { flatMapping, TreeNode } from '../utils/tree.utils';

interface CommandPreviewProps {
  tree: TreeNode;
  commandName: string;
}

const CommandPreview: React.FC<CommandPreviewProps> = ({
  tree,
  commandName,
}) => {
  const mappingStore = useMappingStore();
  const options = flatMapping(tree);

  return (
    <div>
      <h3>Command Preview</h3>
      <p className="leading-8">
        <strong>{`${commandName} `}</strong>
        <br />
        {options.map(
          (option) =>
            option.mapping && (
              <React.Fragment key={option.key}>
                <i>, {option.mapping} </i>
                <span
                  className="underline hover:bg-yellow-200"
                  onMouseEnter={() =>
                    mappingStore.setHoveredCommandMapping(option)
                  }
                  onMouseLeave={() =>
                    mappingStore.setHoveredCommandMapping(undefined)
                  }
                >
                  {`${option.key}`}
                </span>
              </React.Fragment>
            )
        )}
      </p>
    </div>
  );
};

export default CommandPreview;
