import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Popover } from '@headlessui/react';
import React from 'react';
import { useMappingStore } from '../store';
import { TreeNode } from '../utils/tree.utils';

interface DeletionPopoverProps {
  treeNode: TreeNode;
}

const DeletionPopover: React.FC<
  React.PropsWithChildren<DeletionPopoverProps>
> = ({ treeNode, children }) => {
  const [autoAnimateRef] = useAutoAnimate<HTMLDivElement>();
  const mappingStore = useMappingStore();

  return (
    <Popover className="relative" ref={autoAnimateRef}>
      <Popover.Button>{children}</Popover.Button>
      <Popover.Panel className="absolute z-10 bg-white rounded border-gray-600 border-2 px-2 py-1">
        {({ close }: { close: () => void }) => (
          <div className="flex flex-row gap-2">
            <button className="btn primary" type="button" onClick={close}>
              Cancel
            </button>
            <button
              className="btn danger"
              type="button"
              onClick={() => {
                mappingStore.removeMapping(treeNode);
                close();
              }}
            >
              Delete
            </button>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default DeletionPopover;
