import { Dialog } from '@headlessui/react';
import React, { useState } from 'react';
import { useDeleteEndpoint } from '../api/endpoint-api';
import { Endpoint } from '../api/model';

const DeleteEndpointModal: React.FC<
  React.PropsWithChildren<{ endpoint: Endpoint }>
> = ({ children, endpoint }) => {
  const [open, setOpen] = useState(false);
  const { mutate: deleteEndpoint } = useDeleteEndpoint();

  return (
    <>
      <div className="cursor-pointer" onClick={() => setOpen(true)}>
        {children}
      </div>
      <Dialog open={open} onClose={() => setOpen(false)} className="dialog">
        <div className="backdrop">
          <Dialog.Panel className="panel wrap-content flex flex-col">
            <strong className="mx-auto text-lg mb-3">
              Are you sure you want to delete the Endpoint for{' '}
              {endpoint.messageName}?
            </strong>
            <div className="flex flex-grow gap-5">
              <button
                className="btn danger flex flex-1 justify-center"
                onClick={() => {
                  setOpen(false);
                  deleteEndpoint(endpoint);
                }}
              >
                Delete
              </button>
              <button
                className="btn primary flex flex-1 justify-center"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

export default DeleteEndpointModal;
