import { Dialog } from '@headlessui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCreateEndpoint } from '../api/endpoint-api';
import { Endpoint } from '../api/model';

const CreateEndpointModal: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const { mutate: createEndpoint } = useCreateEndpoint();
  const { register, handleSubmit } = useForm<Endpoint>();

  const onSubmit = React.useCallback(
    handleSubmit((values) => {
      createEndpoint(values);
      setOpen(false);
    }),
    [createEndpoint]
  );

  return (
    <>
      <div className="cursor-pointer" onClick={() => setOpen(true)}>
        {children}
      </div>
      <Dialog open={open} onClose={() => setOpen(false)} className="dialog">
        <div className="backdrop">
          <Dialog.Panel className="panel wrap-content flex flex-col">
            <form onSubmit={onSubmit}>
              <div className="form-control">
                <label htmlFor="message-name">Message Name:</label>
                <input
                  id="message-name"
                  type="text"
                  {...register('messageName', { required: true, minLength: 3 })}
                />
              </div>
              <div className="form-control">
                <label htmlFor="http-method">HTTP Method:</label>
                <select
                  id="http-method"
                  {...register('httpMethod', { required: true, minLength: 3 })}
                >
                  <option value="">Select</option>
                  <option value="GET">GET</option>
                  <option value="POST">POST</option>
                  <option value="PUT">PUT</option>
                  <option value="PATCH">PATCH</option>
                  <option value="DELETE">DELETE</option>
                </select>
              </div>
              <div className="form-control">
                <label htmlFor="endpoint">Endpoint: </label>
                <input
                  type="text"
                  id="endpoint"
                  {...register('endpoint', { required: true, minLength: 3 })}
                />
              </div>

              <button className="btn primary w-full mt-3" type="submit">
                Create Endpoint
              </button>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};
export default CreateEndpointModal;
