import { Dialog } from '@headlessui/react';
import React from 'react';

const JsonModal: React.FC<{ content: object }> = ({ content }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} className="dialog">
        <div className="backdrop">
          <Dialog.Panel className="panel">
            <pre>{JSON.stringify(content, null, 4)}</pre>
          </Dialog.Panel>
        </div>
      </Dialog>
      <span
        className="cursor-pointer"
        onDoubleClick={() => setOpen(true)}
      >{`${JSON.stringify(content).substring(0, 30)}...`}</span>
    </>
  );
};

export default JsonModal;
