import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { Tenant } from '../api/model';
import DeleteTenantModal from './delete-tenant-modal';

const TenantItem: React.FC<{ tenant: Tenant }> = ({ tenant }) => {
  return (
    <div className="px-2 py-1 shadow-lg my-2 rounded ring-2 ring-slate-500 relative">
      <DeleteTenantModal tenant={tenant}>
        <FaTrash className="absolute top-1 right-1 text-red-600" />
      </DeleteTenantModal>
      <h4 className="text-lg">{tenant.location.locationCode}</h4>
      <div>
        <label>Tenant: </label>
        <strong>{tenant.tenant} </strong>
      </div>
    </div>
  );
};

export default TenantItem;
