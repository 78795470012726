import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { Endpoint } from '../api/model';
import DeleteEndpointModal from './delete-endpoint-modal';

const EndpointItem: React.FC<{ endpoint: Endpoint }> = ({ endpoint }) => {
  return (
    <div className="px-2 py-1 shadow-lg my-2 rounded ring-2 ring-slate-500 relative">
      <DeleteEndpointModal endpoint={endpoint}>
        <FaTrash className="absolute top-1 right-1 text-red-600" />
      </DeleteEndpointModal>
      <h4 className="text-lg">{endpoint.messageName}</h4>
      <div>
        <label>Endpoint: </label>
        <strong>{endpoint.httpMethod} </strong>
        <i>{endpoint.endpoint}</i>
      </div>
    </div>
  );
};

export default EndpointItem;
