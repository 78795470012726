import { Dialog } from '@headlessui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useThrowError } from '../error-boundary';
import { useMappingStore } from '../store';

interface AddFieldModalProps {
  disabled?: boolean;
}

interface MappingValues {
  optionName: string;
  valuePath: string;
}

const AddFieldModal: React.FC<React.PropsWithChildren<AddFieldModalProps>> = ({
  children,
  disabled,
}) => {
  const throwError = useThrowError();
  const mappingStore = useMappingStore();
  const [open, setOpen] = useState(false);
  const { register, handleSubmit } = useForm<MappingValues>();
  const onOpen = React.useCallback(() => {
    if (!disabled) setOpen(true);
  }, [disabled]);
  const onSubmit = React.useCallback(
    handleSubmit((values) => {
      try {
        mappingStore.addField(values.optionName, values.valuePath);
      } catch (e: unknown) {
        throwError(e);
      } finally {
        setOpen(false);
      }
    }),
    [throwError, mappingStore]
  );
  return (
    <>
      <div className="flex" onClick={onOpen}>
        {children}
      </div>
      <Dialog open={open} onClose={() => setOpen(false)} className="dialog">
        <div className="backdrop">
          <Dialog.Panel className="panel wrap-content">
            <form
              className="flex flex-col justify-between h-full"
              onSubmit={onSubmit}
            >
              <div className="flex flex-col gap-2">
                <div className="form-control">
                  <label htmlFor="option-name">Option Name:</label>
                  <input
                    id="option-name"
                    type="text"
                    placeholder="commandOption"
                    {...register('optionName', { required: true })}
                  />
                </div>
                <div className="form-control">
                  <label htmlFor="value-path">Value Path:</label>
                  <input
                    id="value-path"
                    type="text"
                    placeholder="example.something"
                    {...register('valuePath', { required: true })}
                  />
                </div>
              </div>
              <button type="submit" className="btn primary mt-5">
                Add
              </button>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

export default AddFieldModal;
