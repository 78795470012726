import React from 'react';
import { FaSignInAlt, FaSignOutAlt } from 'react-icons/fa';
import { Direction } from '../api/model';

const DirectionCell: React.FC<{ direction: Direction }> = ({ direction }) => {
  return (
    <div className="flex gap-2">
      {direction === Direction.OUTGOING ? <FaSignOutAlt /> : <FaSignInAlt />}
      {' ' + direction}
    </div>
  );
};
export default DirectionCell;
