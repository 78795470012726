import React from 'react';
import {Message, MessageStatus} from '../api/model';
import {FaPlay} from 'react-icons/fa';
import {useRetryFailed} from '../api/tracing-api';

const ActionCell: React.FC<{ message: Message }> = ({message}) => {
    const {mutate: retryFailed} = useRetryFailed();
    const [isClickable, setIsClickable] = React.useState(true);

    const onClick = React.useCallback(() => {
        if (!isClickable) {
            return;
        }

        setIsClickable(false);

        retryFailed([message]);

        setTimeout(() => {
            setIsClickable(true);
        }, 5000); // 5 seconds cooldown
    }, [isClickable, message, retryFailed]);

    if (
        message.messageStatus !== MessageStatus.FAILED &&
        message.messageStatus !== MessageStatus.FAILED_PERMANENTLY
    ) {
        return null;
    }

    if (!isClickable) {
        return null; // Return null to hide the button during the cooldown period
    }

    return (
        <button onClick={onClick}>
            <FaPlay className="text-slate-500 hover:text-green-600 cursor-pointer"/>
        </button>
    );
};

export default ActionCell;
