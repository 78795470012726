import _ from 'lodash';
import create from 'zustand';
import { insertTreeNode, OptionName, TreeNode } from './utils/tree.utils';

const findNode = (tree: TreeNode, node: TreeNode) => {
  const nodes = [tree];
  let foundNode: TreeNode | null = null;
  while (!foundNode && nodes.length > 0) {
    const n = nodes.pop();
    if (n?.key === node?.key) {
      foundNode = n;
    }
    nodes.push(...(n?.children ?? []));
  }
  return foundNode;
};

export const useMappingStore = create<{
  tree?: TreeNode;
  dirty: boolean;
  hoveredCommandMapping?: TreeNode;
  setTree: (tree: TreeNode | undefined) => void;
  addMapping: (node: TreeNode, mapping: OptionName) => void;
  removeMapping: (node: TreeNode) => void;
  setHoveredCommandMapping: (node: TreeNode | undefined) => void;
  addField: (mapping: OptionName, valuePathL: string) => void;
}>((set) => ({
  tree: undefined,
  dirty: false,
  setTree: (tree: TreeNode | undefined) => set(() => ({ tree, dirty: false })),
  addMapping: (node: TreeNode, mapping: OptionName) =>
    set((state) => {
      if (!state.tree) {
        return {};
      }
      const foundNode = findNode(state.tree, node);
      if (foundNode != null) {
        foundNode.mapping = mapping;
      }
      return { tree: state.tree, dirty: true };
    }),
  removeMapping: (node: TreeNode) =>
    set((state) => {
      if (!state.tree) {
        return {};
      }
      const foundNode = findNode(state.tree, node);
      if (foundNode != null) {
        foundNode.mapping = undefined;
      }
      return { tree: state.tree, dirty: true };
    }),
  setHoveredCommandMapping: (node: TreeNode | undefined) =>
    set(() => ({ hoveredCommandMapping: node })),
  addField: (mapping: OptionName, valuePath: string) =>
    set((state) => {
      const name = valuePath.split('.').pop()!;
      const node: TreeNode = {
        key: valuePath,
        name,
        children: [],
        declaration: {
          type: 'string',
        },
        mapping,
      };
      return {
        tree: insertTreeNode(state.tree!, valuePath, node),
        dirty: true,
      };
    }),
}));
