export enum Direction {
  INCOMING = "INCOMING",
  OUTGOING = "OUTGOING",
}

export interface MessageContent {
  message: {
    messageId: string;
    occurredAt: string;
  };
}

export enum MessageStatus {
  QUEUED = "QUEUED",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  FAILED_PERMANENTLY = "FAILED_PERMANENTLY",
}

export interface MessageError {
  errorCode: number;
  errorText: string;
}

export interface Message {
  messageId: string;
  messageName: string;
  direction: Direction;
  occurredAt: string;
  content: MessageContent;
  messageStatus: MessageStatus;
  transformedInto: Message[];
  messageError: MessageError;
}
