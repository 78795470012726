import create from 'zustand';
import { Direction, MessageStatus } from './api/model';

type Falsy = undefined;

export interface SearchValues {
  message_name?: string;
  direction?: Direction | Falsy;
  occurred_from?: Date | Falsy;
  occurred_until?: Date | Falsy;
  message_status?: MessageStatus | Falsy;
  content?: string;
  messages_per_page?: number | Falsy;
  page?: number | Falsy;
}

type SearchStore = SearchValues & {
  setSearchValues(searchValues: SearchValues): void;
};

export const useSearchStore = create<SearchStore>((set) => ({
  setSearchValues: ({ occurred_from, occurred_until, ...values }) =>
    set({
      ...values,
      occurred_from:
        occurred_from && !isNaN(occurred_from as any)
          ? occurred_from
          : undefined,
      occurred_until:
        occurred_until && !isNaN(occurred_until as any)
          ? occurred_until
          : undefined,
    }),
}));
