import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Tenant } from './model';
import axios from 'axios';
import { useThrowError } from '../../mapping/error-boundary';

export const useTenants = () => {
  const throwError = useThrowError();
  return useQuery(
    ['tenants'],
    () => axios.get<Tenant[]>('/rest/api/tenant/all').then((res) => res.data),
    {
      onError(err) {
        throwError(err);
      },
    }
  );
};

export const useCreateTenant = () => {
  const throwError = useThrowError();
  const queryClient = useQueryClient();
  return useMutation(
    (values: { location: string; tenant: number }) =>
      axios.post<Tenant>('/rest/api/tenant', values).then((res) => res.data),
    {
      onSuccess: () => queryClient.invalidateQueries(['tenants']),
      onError(err) {
        throwError(err);
      },
    }
  );
};

export const useDeleteTenant = () => {
  const throwError = useThrowError();
  const queryClient = useQueryClient();
  return useMutation(
    (tenant: Tenant) =>
      axios
        .delete<void>(`/rest/api/tenant/${tenant.id}`)
        .then((res) => res.data),
    {
      onSuccess: () => queryClient.invalidateQueries(['tenants']),
      onError(err) {
        throwError(err);
      },
    }
  );
};
