import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Popover } from '@headlessui/react';
import React, { useState } from 'react';
import { useMappingStore } from '../store';
import { TreeNode } from '../utils/tree.utils';

interface MappingPopoverProps {
  treeNode: TreeNode;
}

const MappingPopover: React.FC<
  React.PropsWithChildren<MappingPopoverProps>
> = ({ treeNode, children }) => {
  const [autoAnimateRef] = useAutoAnimate<HTMLDivElement>();
  const mappingStore = useMappingStore();
  const [mapping, setMapping] = useState(treeNode.mapping);
  const handleAdd = React.useCallback(() => {
    if (mapping) {
      mappingStore.addMapping(treeNode, mapping);
    }
  }, [mapping, treeNode]);

  return (
    <Popover className="relative" ref={autoAnimateRef}>
      <Popover.Button>{children}</Popover.Button>
      <Popover.Panel className="bg-white absolute z-10 rounded border-gray-600 border-2 px-2 py-1">
        {({ close }: { close: () => void }) => (
          <div className="flex flex-row gap-2">
            <input
              type="text"
              value={mapping}
              onChange={(e) => setMapping(e.target.value)}
            />
            <button
              className="btn primary"
              type="button"
              onClick={() => {
                handleAdd();
                close();
              }}
            >
              Save
            </button>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default MappingPopover;
