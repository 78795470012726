import axios from 'axios';
import _ from 'lodash';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { resolve } from '../utils/schema.utils';
import { MessageMapping, ValueMapping } from './model';
import { MessageName } from '../../../api/message-api';
import { useThrowError } from '../error-boundary';

export const useMessageMapping = (name: MessageName | undefined) => {
  return useQuery(
    [`messagemapping`, name],
    () =>
      axios
        .get<MessageMapping>(`/rest/api/mapping/${name}`)
        .then((res) => res.data),
    {
      enabled: !!name,
    }
  );
};

export const useUpdateMessageMapping = (name: string | null | undefined) => {
  const queryClient = useQueryClient();
  return useMutation(
    (valueMappings: ValueMapping[]) =>
      axios.put<MessageMapping>(`/rest/api/mapping/${name}`, valueMappings),
    {
      onSuccess() {
        // TODO: Do some Optimistic Updates
        queryClient.invalidateQueries([name]);
      },
    }
  );
};

export const useCreateMessageMapping = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (messageMapping: MessageMapping) =>
      axios.post<MessageMapping>(`/rest/api/mapping`, {
        ...messageMapping,
        valueMappings: [],
      } as MessageMapping), {
      onSuccess: () => queryClient.invalidateQueries(['messagenames']),
    }
  );
};

export const useMessageDeclaration = (name: MessageName | null | undefined) => {
  const { data: apiDoc } = useQuery(
    ['apidoc'],
    () => axios.get(`/api-docs`).then((res) => res.data),
    { enabled: !!name }
  );
  const operations = React.useMemo(
    () =>
      _.values(apiDoc?.paths).flatMap((path) =>
        [
          path['get'],
          path['post'],
          path['put'],
          path['delete'],
          path['patch'],
        ].filter((p) => !!p)
      ),
    [apiDoc]
  );
  const operationId = React.useMemo(() => name && _.camelCase(name), [name]);
  const throwError = useThrowError();
  return React.useMemo(
    () => {
      try {
        return resolve(
          _.get(
            operations?.find((op) => op.operationId === operationId),
            'requestBody.content.application/json.schema'
          ),

          apiDoc?.components?.schemas
        );
      } catch (e: unknown) {
        throwError(e);
      }
    },
    [apiDoc, operations, operationId, throwError]
  );
};
