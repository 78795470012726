import React from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Direction, Message, MessageStatus } from '../api/model';
import DirectionCell from './direction-cell';
import { parseISO } from 'date-fns';
import MessageStatusCell from './message-status-cell';
import { JsonCell } from './json-cell';
import _ from 'lodash';
import ActionCell from './action-cell';
import { useAllMessages } from '../api/tracing-api';

const columns: ColumnDef<Message>[] = [
  {
    header: () => <span>Id</span>,
    accessorKey: 'messageId',
  },
  {
    header: () => <span>Message Type</span>,
    accessorKey: 'messageName',
  },
  {
    header: () => <span>Direction</span>,
    accessorKey: 'direction',
    cell: (info) => <DirectionCell direction={info.getValue() as Direction} />,
  },
  {
    header: () => <span>Occurred At</span>,
    accessorKey: 'occurredAt',
    cell: (info) => <>{parseISO(info.getValue() as string).toLocaleString()}</>,
  },
  {
    header: () => <span>Status</span>,
    accessorKey: 'messageStatus',
    cell: (info) => (
      <MessageStatusCell messageStatus={info.getValue() as MessageStatus} />
    ),
  },
  {
    header: () => <span>Content</span>,
    accessorKey: 'content',
    cell: (info) => <JsonCell content={info.getValue() as object} />,
  },
  {
    header: () => <span>Transformed Into</span>,
    accessorKey: 'transformedInto',
    cell: (info) => <JsonCell content={info.getValue() as object} />,
  },
  {
    header: () => <span>Error</span>,
    id: 'messageError',
    accessorFn: (info) =>
      info.messageError || _.first(info.transformedInto)?.messageError,
    cell: (info) => <JsonCell content={info.getValue() as object} />,
  },
  {
    id: 'actions',
    header: () => <span>Actions</span>,
    cell: (info) => <ActionCell message={info.row.original} />,
  },
];

const ResultTable: React.FC = () => {
  const { data: messages, isLoading: messagesLoading } = useAllMessages();
  const table = useReactTable({
    columns,
    data: messages ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  if (messagesLoading) {
    return <div>Loading...</div>;
  }

  if (messages?.length === 0) {
    return <div>No messages found</div>;
  }

  return (
    <table className="table-fixed border-l-2 border-t-2">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="border-b-2">
            {headerGroup.headers?.map((header) => (
              <th key={header.id} className="border-r-2">
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            className={`border-b-2 ${row.getIsSelected() && 'bg-slate-300'}`}
          >
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="border-r-2 px-2">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ResultTable;
