import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Endpoint } from './model';

export const useEndpoints = () => {
  return useQuery(['endpoints'], () =>
    axios.get<Endpoint[]>('/rest/api/endpoint/all').then((res) => res.data)
  );
};

export const useCreateEndpoint = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (endpoint: Endpoint) =>
      axios
        .post<Endpoint>('/rest/api/endpoint/', endpoint)
        .then((res) => res.data),
    {
      onSuccess: () => queryClient.invalidateQueries(['endpoints']),
    }
  );
};

export const useDeleteEndpoint = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (endpoint: Endpoint) =>
      axios
        .delete<void>(`/rest/api/endpoint/${endpoint.messageName}`)
        .then((res) => res.data),
    { onSuccess: () => queryClient.invalidateQueries(['endpoints']) }
  );
};
