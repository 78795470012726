import React, { MouseEvent, useEffect } from 'react';
import { useSearchStore } from '../searchStore';
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from 'react-icons/md';
import { useMessageCount } from '../api/tracing-api';
import { range } from 'lodash';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form';

// TODO: use register to save submit values in Form state, instead of saving
//  them in the SearchStore on every call.
//  For some reason Zustand only saves values that are also saved in the
//  Form state which will cause api calls with missing values if they are not
//  set explicitly via setSearchValues(...values).
type PaginationProps = {
  register: UseFormRegister<FieldValues>;
};

const Pagination: React.FC<PaginationProps> = ({ register }) => {
  const { setSearchValues, messages_per_page, page, ...values } =
    useSearchStore();

  const { data: messagesCount, isLoading: messagesCountLoading } =
    useMessageCount();

  let pageCount = -1;
  if (messagesCount && messages_per_page) {
    const pageCalculation = messagesCount / messages_per_page;
    pageCount = Math.floor(pageCalculation) + 1;
    if (Number.isInteger(pageCalculation)) {
      pageCount -= 1;
    }
  }

  const handleStartPage = () => {
    let _page = 1;
    if (page && page <= pageCount) {
      _page = page;
    }
    setSearchValues({
      page: _page,
      ...values,
    });
  };

  useEffect(handleStartPage, [pageCount]);

  if (pageCount === -1) {
    return <div></div>;
  }

  const getNumberedPageHandler = (pageNumber: number) => {
    return (e: MouseEvent) => {
      e.preventDefault();
      setSearchValues({ page: pageNumber, ...values });
    };
  };

  const numberedPageButton = (pageButtonNumber: number, disabled = false) => {
    return (
      <button
        type="submit"
        disabled={disabled}
        className="mr-1 basis-1/5 rounded-full btn primary"
        onClick={getNumberedPageHandler(pageButtonNumber)}
      >
        {pageButtonNumber}
      </button>
    );
  };

  const navigationButton = (pageButtonNumber: number, disabled = false) => {
    const iconClasses = 'min-h-[50%] min-w-[50%] basis-1/5';
    let icon =
      page && pageButtonNumber < page ? (
        <MdOutlineArrowBackIos className={iconClasses} />
      ) : (
        <MdOutlineArrowForwardIos className={iconClasses} />
      );

    return (
      <button
        className={`mr-1 basis-1/5 rounded-full primary flex justify-center items-center ${
          disabled ? 'disabled' : 'btn'
        }`}
        onClick={getNumberedPageHandler(pageButtonNumber)}
        disabled={disabled}
        type="submit"
      >
        {icon}
      </button>
    );
  };

  let output;
  if (pageCount < 6) {
    output = range(pageCount).map((_page) => {
      return numberedPageButton(_page + 1);
    });
  } else {
    output = [
      numberedPageButton(1),
      navigationButton(page! - 1, page === 1),
      numberedPageButton(page!, true),
      navigationButton(page! + 1, page === pageCount),
      numberedPageButton(pageCount),
    ];
  }

  const paginationDivWrapper = (...components: JSX.Element[]) => {
    return <div className="flex h-2/3 justify-center">{components}</div>;
  };

  return paginationDivWrapper(...output);
};
export default Pagination;
