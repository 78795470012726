import { useAutoAnimate } from '@formkit/auto-animate/react';
import React from 'react';
import {
  FaCaretDown,
  FaCaretRight,
  FaExclamationTriangle,
  FaLongArrowAltRight,
  FaPen,
  FaTrash,
} from 'react-icons/fa';
import { useMappingStore } from '../store';
import { TreeNode } from '../utils/tree.utils';
import DeletionPopover from './deletion-popover';
import MappingPopover from './mapping-popover';

interface TreeProps {
  node: TreeNode;
  root?: boolean;
}

const Tree: React.FC<TreeProps> = ({ node, root = false }) => {
  const [autoAnimateRef] = useAutoAnimate<HTMLDivElement>();
  const [isExpanded, setIsExpanded] = React.useState(true);
  const isLeaf = node.children.length === 0;
  const { hoveredCommandMapping } = useMappingStore();
  return (
    <div ref={autoAnimateRef}>
      {!root && (
        <div className="flex items-center">
          {!isLeaf && (
            <div onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded && <FaCaretDown className="cursor-pointer" />}
              {!isExpanded && <FaCaretRight className="cursor-pointer" />}
            </div>
          )}
          <div
            className={`flex items-center gap-2 ${
              hoveredCommandMapping === node && 'bg-yellow-200'
            }`}
          >
            {isLeaf && !node.mapping && (
              <FaExclamationTriangle className="text-yellow-500" />
            )}
            {node.name}
            {isLeaf && node.mapping && (
              <>
                <FaLongArrowAltRight />
                {node.mapping}
              </>
            )}
            {isLeaf && (
              <MappingPopover treeNode={node}>
                <FaPen className="text-slate-500 cursor-pointer" />
              </MappingPopover>
            )}
            {node.mapping && (
              <DeletionPopover treeNode={node}>
                <FaTrash className="text-red-600 cursor-pointer" />
              </DeletionPopover>
            )}
          </div>
        </div>
      )}
      {isExpanded && (
        <div className={`${!root && 'ml-8'}`}>
          {node.children.map((n) => (
            <Tree key={`${node.key}-${n.key}`} node={n} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Tree;
