import { Dialog } from '@headlessui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCreateMessageMapping } from '../api/mapping-api';
import { MessageMapping } from '../api/model';
import { useThrowError } from '../error-boundary';
import { useMessageNames } from '../../../api/message-api';

const CreateMappingModal: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const throwError = useThrowError();
  const [open, setOpen] = useState(false);
  const { data: messageNames } = useMessageNames();
  const { mutate: createMapping } = useCreateMessageMapping();
  const { register, handleSubmit } = useForm<MessageMapping>();

  const onSubmit = React.useCallback(
    handleSubmit((mapping) => {
      if (messageNames?.find(message => mapping.messageName.toLowerCase() === message.toLowerCase())) {
        throwError({
          message: 'mapping already exists',
        });
        return;
      }
      try {
        createMapping(mapping);
      } catch (e: unknown) {
        throwError(e);
      }
      setOpen(false);
    }),
    [createMapping, messageNames]
  );

  return (
    <>
      <div className="flex" onClick={() => setOpen(true)}>
        {children}
      </div>
      <Dialog open={open} onClose={() => setOpen(false)} className="dialog">
        <div className="backdrop">
          <Dialog.Panel className="panel wrap-content flex flex-col">
            <form onSubmit={onSubmit}>
              <div className="form-control">
                <label htmlFor="message-name">Message Name:</label>
                <input
                  id="message-name"
                  type="text"
                  {...register('messageName', { required: true, minLength: 3 })}
                />
              </div>
              <div className="form-control">
                <label htmlFor="http-method">Command Name:</label>
                <input
                  id="http-method"
                  type="text"
                  {...register('cmdName', { required: true, minLength: 3 })}
                />
              </div>
              <button className="btn primary w-full mt-3" type="submit">
                Create Mapping
              </button>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};
export default CreateMappingModal;
