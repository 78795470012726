import React from 'react';
import { useTenants } from './api/tenant-api';
import CreateTenantModal from './components/create-tenant-modal';
import TenantItem from './components/tenant-item';

const Tenant: React.FC = () => {
  const { data: tenants } = useTenants();
  return (
    <div className="flex flex-col p-2">
      <h1 className="text-3xl mb-8">Tenants</h1>
      <CreateTenantModal>
        <button className="btn primary">Create New Tenant</button>
      </CreateTenantModal>
      {tenants?.map((tenant) => (
        <TenantItem key={tenant.id} tenant={tenant} />
      ))}
    </div>
  );
};

export default Tenant;
