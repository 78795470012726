import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export type MessageName = string;

export const useMessageNames = () => {
  return useQuery(['messagenames'], () =>
    axios.get<MessageName[]>('/rest/api/mapping/names').then((res) => res.data)
  );
};
