import create from 'zustand';

export interface AuthInfo {
  authenticated: boolean;
  inProgress: boolean;
  tokens?: {
    accessToken?: string;
    refreshToken?: string;
  };
  userData?: {
    preferred_username?: string;
  };
}
export const useAuthStore = create<AuthInfo>(() => ({
  authenticated: false,
  inProgress: false,
}));
