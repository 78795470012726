import { useKeycloak } from '@react-keycloak/web';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import { Capabilities } from './types';

export type Roles =
  | 'incoming_mapping'
  | 'tracing'
  | 'tenant_mapping'
  | 'offline_access'
  | 'uma_authorization'
  | 'tracing_retry'
  | 'default-roles-vds-interface'
  | 'endpoint_mapping';

export const useHasRoles = (roles: Roles[]) => {
  const { keycloak } = useKeycloak();
  const { data: capabilities } = useQuery(['capabilities'], () =>
    axios.get<Capabilities>('/rest/api/system/capabilities').then((d) => d.data)
  );
  const client_roles = _.get(
    keycloak.tokenParsed?.resource_access,
    capabilities?.auth?.clientId as string
  )?.roles;

  return roles.map(
    (role) => keycloak?.hasRealmRole(role) || client_roles?.includes(role)
  );
};
