import { ReactKeycloakProvider } from '@react-keycloak/web';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import keycloak from './auth/keycloak';
import { Routes } from './routes';

const queryClient = new QueryClient();

const eventLogger = (eventType: unknown) => {
  console.log(eventType);
};

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactKeycloakProvider
        authClient={keycloak!}
        onEvent={eventLogger}
        onTokens={(tokens) => console.log(tokens)}
      >
        <Routes />
      </ReactKeycloakProvider>
    </QueryClientProvider>
  );
};

export default App;
