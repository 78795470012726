import { useNavigate } from '@tanstack/react-location';
import React, { useEffect } from 'react';
import { useHasRoles } from './auth/roles';

const AuthCheck: React.FC = () => {
  const roles = useHasRoles([
    'incoming_mapping',
    'tracing',
    'endpoint_mapping',
    'tenant_mapping',
  ]);
  const navigate = useNavigate();
  useEffect(() => {
    if (roles.every((r) => r === false)) {
      navigate({ to: '/noauth' });
    }
  }, [navigate, roles]);
  return <></>;
};

export default AuthCheck;
