import { useKeycloak } from '@react-keycloak/web';
import {
  DefaultGenerics,
  Link,
  Outlet,
  ReactLocation,
  Route,
  Router,
  useLocation,
  useNavigate,
  useRouter,
} from '@tanstack/react-location';
import _ from 'lodash';
import React, { useEffect } from 'react';
import AuthCheck from './auth-check';
import { useAuthStore } from './auth/auth-store';
import { useHasRoles } from './auth/roles';
import EndpointMapping from './pages/endpoint';
import Mapping from './pages/mapping';
import NoAuth from './pages/NoAuth';
import Tenant from './pages/tenant';
import Tracing from './pages/tracing';

const location = new ReactLocation();

const routes: Route<DefaultGenerics>[] = [
  { path: '/', element: <Tracing /> },
  { path: '/mapping', element: <Mapping /> },
  { path: '/endpoint', element: <EndpointMapping /> },
  { path: '/tenant', element: <Tenant /> },
  { path: '/noauth', element: <NoAuth /> },
];

interface AppLinkProps {
  to: string;
}

const AppLink: React.FC<React.PropsWithChildren<AppLinkProps>> = ({
  to,
  children,
}) => {
  return (
    <li className="pl-2">
      <Link to={to} className="text-xl hover:underline">
        - {children}
      </Link>
    </li>
  );
};

export const Routes: React.FC = () => {
  const { authenticated, inProgress } = useAuthStore();
  const [incomginMapping, tracing, endpointMapping, tenantMapping] =
    useHasRoles([
      'incoming_mapping',
      'tracing',
      'endpoint_mapping',
      'tenant_mapping',
    ]);

  if (inProgress) return <div className="text-3xl">Auth in Progress</div>;
  else if (authenticated)
    return (
      <Router location={location} routes={routes}>
        <div className="grid grid-cols-12 h-screen">
          <ul className="col-span-2 flex flex-col justify-start p-1 bg-slate-200 h-full mr-2">
            {tracing && <AppLink to="/">Tracing</AppLink>}
            {incomginMapping && <AppLink to="/mapping">Mapping</AppLink>}
            {endpointMapping && (
              <AppLink to="/endpoint">Endpoint Mapping</AppLink>
            )}
            {tenantMapping && <AppLink to="/tenant">Tenant Mapping</AppLink>}
          </ul>
          <div className="col-span-10">
            <AuthCheck />
            <Outlet />
          </div>
        </div>
      </Router>
    );
  else return <div className="text-xl">Authentication Failed</div>;
};
