import _ from 'lodash';

interface ParameterMap {
  [key: string]: string | null | undefined | number;
}

export const addUrlParameters = (url: string, params: ParameterMap) => {
  const urlParameters = Object.entries(params)
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}=${encodeURIComponent(value!)}`)
    .reduce((c, n) => c + '&' + n, '');
  return url + '?' + _.trim(urlParameters, '&');
};
