import React from 'react';
import SearchBar from './components/search-bar';
import ResultTable from './components/result-table';

const Tracing: React.FC = () => {
  return (
    <div className="p-2">
      <SearchBar />
      <ResultTable />
    </div>
  );
};

export default Tracing;
