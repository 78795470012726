import { useAutoAnimate } from '@formkit/auto-animate/react';
import React from 'react';
import { useEndpoints } from './api/endpoint-api';
import CreateEndpointModal from './components/create-endpoint-modal';
import EndpointItem from './components/endpoint-item';

const EndpointMapping: React.FC = () => {
  const [autoAnimateRef] = useAutoAnimate<HTMLDivElement>();
  const { data: endpoints } = useEndpoints();

  return (
    <div className="flex flex-col p-2" ref={autoAnimateRef}>
      <h1 className="text-3xl mb-8">Endpoint Mapping</h1>
      <CreateEndpointModal>
        <button className="btn primary">Create New Endpoint</button>
      </CreateEndpointModal>
      {endpoints?.map((endpoint) => (
        <EndpointItem key={endpoint.messageName} endpoint={endpoint} />
      ))}
    </div>
  );
};

export default EndpointMapping;
