import _ from 'lodash';
import { Declaration, ValueMapping } from '../api/model';

export type OptionName = string;

export interface TreeNode {
  key: string;
  name: string;
  declaration: Declaration;
  mapping?: OptionName;
  children: TreeNode[];
}

export const mapDeclarationToTreeNode = (
  key: string,
  name: string,
  declaration: Declaration,
  valueMappings: ValueMapping[]
): TreeNode => {
  if (declaration.type === 'integer' || declaration.type === 'string') {
    return {
      key,
      name,
      declaration,
      children: [],
      mapping: valueMappings.find((m) => m.valuePath === key)?.optionName,
    };
  }

  const children = _.entries(declaration.properties)
    .map(([childName, declaration]) =>
      mapDeclarationToTreeNode(
        name ? `${name}.${childName}` : childName,
        childName,
        declaration,
        valueMappings
      )
    )
    // The Server Side thinks there is an "empty" property on each Message becasue they inherit from HashMap & that Property actually exists there.
    .filter((child) => child.key !== 'empty')
    .sort((a, b) => a.key.localeCompare(b.key));

  return {
    key: name,
    name,
    children,
    declaration,
  };
};

export const flatMapping = (node: TreeNode): TreeNode[] => {
  return [node, ...node.children.flatMap(flatMapping)];
};

export const insertTreeNode = (
  root: TreeNode,
  valuePath: string,
  node: TreeNode
): TreeNode => {
  const paths = valuePath.split('.');
  const name = paths.pop();
  let jsonPath = '';
  let cNode = root;
  for (const path of paths) {
    jsonPath += _.trimStart(`.${path}`, '.');
    const child = cNode.children.find((c) => c.key === jsonPath);
    if (child) {
      cNode = child;
    } else {
      const newNode: TreeNode = {
        key: jsonPath,
        name: path,
        children: [],
        declaration: {
          type: 'string',
        },
      };
      cNode.children.push(newNode);
      cNode = newNode;
    }
  }
  if (!!cNode.children.find((c) => c.key === jsonPath + '.' + name)) {
    throw new Error('Cannot override Leaf if it already has a mapping.');
  }
  cNode.children.push(node);
  return _.cloneDeep(root);
};
